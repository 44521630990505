<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <!-- Icons -->
      <BoxView title="admin package Common Icons" :grid="100">
        <div class="list_icon">
          <div class="item_icon" v-for="(svgName , svgIndex) in iconSvgCommonName" :key="`svgCommon${svgIndex}`">
            <IconSvg :iconName="svgName" :size="30" iconColor="#222" iconDarkColor="#fff"/>
            <strong class="tit_icon">{{ svgName }}</strong>
          </div>
        </div>
      </BoxView>
      <BoxView title="admin personal Icons" :grid="100">
        <div class="list_icon">
          <div class="item_icon" v-for="(svgName , svgIndex) in iconSvgName" :key="`svg${svgIndex}`">
            <IconSvg :iconName="svgName" :size="30" iconColor="#222" iconDarkColor="#fff"/>
            <strong class="tit_icon">{{ svgName }}</strong>
          </div>
        </div>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import IconSvgCommonPath from "@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvgPath";
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';

import IconSvgPath from "@/components/common/icon/IconSvgPath";

export default {
  name:'Icons',
  components:{
    PageWithLayout,
    BoxView,
    IconSvg,
  },
  computed:{
    iconSvgCommonName(){
      return Object.keys(IconSvgCommonPath)
    },
    iconSvgName(){
      return Object.keys(IconSvgPath)
    },
  },
}
</script>

<style scoped>
/* svg icon */
.list_icon{text-align:center}
.item_icon{display:inline-block;width:160px;margin:4px;padding:14px 8px;border:1px solid #e1e1e1;vertical-align:top}
.item_icon .tit_icon{display:block;margin-top:14px;font-size:14px;line-height:16px}
</style>